import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  formatNumberWithCommas,
  SectionPassageType,
  StageDataType,
  StageType,
  SubActivityDataType,
  SubActivityType,
  VoyageResult,
} from "@copmer/calculator-widget";
import { ComponentProps, Fragment } from "react";
import { valueSuffixConstructor } from "./utils";
import { cn } from "@/lib/utils";
import { DebugTableKeyVal } from "./DebugTable";
import Icon from "./Icon";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { SizeRestrictionWarning } from "./SizeRestrictionWarning";

const TableHeadCell = ({
  children,
  className,
  ...rest
}: ComponentProps<typeof TableHead>) => (
  <TableHead
    {...rest}
    className={cn(
      "text-sm leading-none font-light text-frGrey-300 pb-4 text-opacity-70",
      className
    )}
  >
    {children}
  </TableHead>
);

const TableBodyCell = ({
  children,
  className,
  ...rest
}: ComponentProps<typeof TableCell>) => (
  <TableCell
    {...rest}
    className={cn(
      "text-base leading-none font-light text-frGrey-300 py-2",
      className
    )}
  >
    {children}
  </TableCell>
);

export default function DebugTableDetails({
  result,
}: {
  result: VoyageResult;
}) {
  const renderStageName = (stage: StageDataType) => {
    let rowSpan = stage.subactivities.length + 1;

    if (stage.name.includes("speed:")) {
      const label = stage.name.split("VLSFO")[0];

      const vlsfoSpeed = /vlsfo sailing speed: ([\d\.]+)\s?([\w\/]+)/i.exec(
        stage.name
      );
      const lsmgoSpeed = /lsmgo sailing speed: ([\d\.]+)\s?([\w\/]+)/i.exec(
        stage.name
      );

      return (
        <TableBodyCell rowSpan={rowSpan} className="h-inherit pl-0">
          <div className="flex flex-col w-full h-full py-2 max-[1300px]:gap-2">
            <div>{label}</div>
            <div className="flex-grow" />
            <div>
              <DebugTableKeyVal
                value={valueSuffixConstructor(
                  vlsfoSpeed?.[1] ?? "",
                  vlsfoSpeed?.[2] ?? ""
                )}
                label="VLSFO sailing speed"
              />
            </div>
            <div className="flex-grow" />
            <div>
              <DebugTableKeyVal
                value={valueSuffixConstructor(
                  lsmgoSpeed?.[1] ?? "",
                  lsmgoSpeed?.[2] ?? ""
                )}
                label="LSMGO sailing speed"
              />
            </div>
          </div>
        </TableBodyCell>
      );
    }

    return (
      <TableBodyCell rowSpan={rowSpan} className="pl-0">
        {stage.name}

        {stage.stageType === StageType.Port ? (
          <SizeRestrictionWarning
            sizeRestriction={result.debugData.sizeRestrictions?.find(
              (sR) => sR.portCallIndex === stage.portCallIndex
            )}
            className="align-sub"
          />
        ) : null}
      </TableBodyCell>
    );
  };

  return (
    <div className="pt-4">
      <Table className="w-full h-64 [&_tr_th:first-child]:pl-0 [&_tr_th:last-child]:pr-0">
        <TableHeader className="px-0">
          <TableRow className="border-0">
            <TableHeadCell className="w-[18%]">Activity</TableHeadCell>
            <TableHeadCell className="w-[20%]">Subactivity</TableHeadCell>
            <TableHeadCell className="text-right">Cost days</TableHeadCell>
            <TableHeadCell className="w-[9%] text-right">
              Bunker VLSFO mts
            </TableHeadCell>
            <TableHeadCell className="w-[9%] text-right">
              Bunker LSMGO mts
            </TableHeadCell>
            <TableHeadCell className="w-[9%] text-right">
              EU ETS carbon mts
            </TableHeadCell>
            <TableHeadCell className="text-right">Cost $</TableHeadCell>
            <TableHeadCell className="text-right">Draft m</TableHeadCell>
            <TableHeadCell className="w-[14%] text-right">
              Sea/Port Margin %
            </TableHeadCell>
            <TableHeadCell className="text-right">Verification</TableHeadCell>
            <TableHeadCell className="w-[10%] text-right">Sum $</TableHeadCell>
          </TableRow>
        </TableHeader>

        {result.debugData.stages.map((stage, i) => (
          <Fragment key={i}>
            <TableBody className="border-b border-frGrey-525">
              <TableRow key={i} className="border-0 h-[1px]">
                {renderStageName(stage)}
              </TableRow>
              {stage.subactivities.map((subactivity, j) => (
                <TableRow
                  key={j}
                  className="border-0 [&:nth-child(2)>td]:pt-4 [&:last-child>td]:pb-4"
                >
                  <TableBodyCell>
                    {subactivity.subactivity}

                    {stage.distance &&
                    subactivity.subactivity.includes("->") ? (
                      <div className="text-sm mt-2 font-light text-frGrey-400 leading-none grid grid-cols-2 grid-cols-[50%,30%]">
                        <span className="whitespace-nowrap">
                          Distance non-seca:
                        </span>
                        <span className="text-right">
                          {valueSuffixConstructor(
                            formatNumberWithCommas(
                              stage.distance.nonSeca,
                              true
                            ),
                            "nm"
                          )}
                        </span>

                        <span>Distance seca:</span>
                        <span className="text-right">
                          {valueSuffixConstructor(
                            formatNumberWithCommas(stage.distance.seca, true),
                            "nm"
                          )}
                        </span>
                      </div>
                    ) : null}
                  </TableBodyCell>

                  {subactivity.subActivityType ===
                  SectionPassageType.RoutePoint ? (
                    <TableBodyCell colSpan={9} />
                  ) : (
                    <>
                      <TableBodyCell className="text-right">
                        {subactivity.costInDays
                          ? formatNumberWithCommas(subactivity.costInDays)
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        {subactivity.bunkersInMts.vlsfo
                          ? formatNumberWithCommas(
                              subactivity.bunkersInMts.vlsfo
                            )
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        {subactivity.bunkersInMts.lsmgo
                          ? formatNumberWithCommas(
                              subactivity.bunkersInMts.lsmgo
                            )
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        <EUETSValueRenderer
                          label={subactivity.subactivity}
                          value={subactivity.euEtsCarbonInMts}
                          subActivityType={subactivity.subActivityType}
                          euETSActivityTotal={findActivityEUETS(
                            stage.subactivities
                          )}
                        />
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        {subactivity.costInDollars
                          ? formatNumberWithCommas(subactivity.costInDollars)
                          : "-"}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        {subactivity.draft
                          ? formatNumberWithCommas(subactivity.draft)
                          : ""}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        {subactivity.margin
                          ? formatNumberWithCommas(subactivity.margin)
                          : ""}
                      </TableBodyCell>
                      <TableBodyCell className="text-right">
                        <VerifiedEstimatedIcon
                          value={subactivity.verifiedEstimated}
                        />
                      </TableBodyCell>
                      <TableBodyCell className="text-right pr-0">
                        {valueSuffixConstructor(
                          formatNumberWithCommas(subactivity.sumInDollars),
                          "$"
                        )}
                      </TableBodyCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Fragment>
        ))}

        {/* Totals */}
        <TableBody>
          <TableRow className="border-0 [&>td]:pt-4">
            <TableBodyCell></TableBodyCell>
            <TableBodyCell>Total</TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.costInDays
                ? formatNumberWithCommas(result.debugData.totalCost.costInDays)
                : "-"}
            </TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.bunkersInMts.vlsfo
                ? formatNumberWithCommas(
                    result.debugData.totalCost.bunkersInMts.vlsfo
                  )
                : "-"}
            </TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.bunkersInMts.lsmgo
                ? formatNumberWithCommas(
                    result.debugData.totalCost.bunkersInMts.lsmgo
                  )
                : "-"}
            </TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.euEtsCarbonInMts
                ? formatNumberWithCommas(
                    result.debugData.totalCost.euEtsCarbonInMts
                  )
                : "-"}
            </TableBodyCell>
            <TableBodyCell className="text-right"></TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.draft
                ? formatNumberWithCommas(result.debugData.totalCost.draft)
                : ""}
            </TableBodyCell>
            <TableBodyCell className="text-right">
              {result.debugData.totalCost.margin
                ? formatNumberWithCommas(result.debugData.totalCost.margin)
                : ""}
            </TableBodyCell>
            <TableBodyCell className="text-right"></TableBodyCell>
            <TableBodyCell className="text-right">
              {valueSuffixConstructor(
                formatNumberWithCommas(result.debugData.totalCost.sumInDollars),
                "$"
              )}
            </TableBodyCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

const findActivityEUETS = (subactivities: SubActivityDataType[]) => {
  return subactivities.find(
    (subactivity) => subactivity.subActivityType === SubActivityType.EU_ETS
  )?.euEtsCarbonInMts;
};

const EUETSValueRenderer = ({
  label,
  value,
  subActivityType,
  euETSActivityTotal,
}: {
  label: string;
  value: SubActivityDataType["euEtsCarbonInMts"];
  subActivityType: SubActivityDataType["subActivityType"];
  euETSActivityTotal?: SubActivityDataType["euEtsCarbonInMts"];
}) => {
  const formatted = value ? formatNumberWithCommas(value) : "-";

  if (subActivityType === SubActivityType.EU_ETS || formatted === "-") {
    return <span>{formatted}</span>;
  }

  let tooltip = "";

  let rowLabel = "this row";

  if (label) {
    if (label.includes("->")) {
      rowLabel = "sailing";
    } else if (label.includes("operation")) {
      rowLabel = "this operation";
    } else {
      rowLabel = label;
    }
  }

  if (euETSActivityTotal) {
    tooltip = `${formatted} MTS carbon out of ${formatNumberWithCommas(
      euETSActivityTotal
    )} MTS carbon was contributed by ${rowLabel}`;
  }

  let body = <span className="text-frGrey-500">{formatted}</span>;

  if (tooltip) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>{body}</TooltipTrigger>
          <TooltipContent className="bg-frGrey-800 border-frGrey-500 max-w-60 font-normal text-sm text-center">
            <p>{tooltip}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return body;
};

export const VerifiedEstimatedIcon = ({
  value,
}: {
  value: SubActivityDataType["verifiedEstimated"];
}) => {
  let body = null;
  let tooltip = "...";

  if (value === "Verified") {
    body = <Icon icon="EyeCheck" className="w-4 h-4 text-frGrey-400" />;

    tooltip = value;
  } else {
    body = (
      <span className="flex flex-col gap-2 items-center gap-0.5 justify-end text-sm leading-none font-light">
        <Icon icon="InfoCircleFilled" className="h-4 w-4 text-frGrey-300" />
        {value.label}
      </span>
    );

    tooltip = value.tooltip;
  }

  return (
    <div className="flex justify-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>{body}</TooltipTrigger>
          <TooltipContent className="bg-frGrey-800 border-frGrey-500 max-w-60 font-normal text-sm">
            <p className="text-center">{tooltip}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
