import { cn } from "@/lib/utils";
import { CheckCircledIcon, InfoCircledIcon } from "@radix-ui/react-icons";

interface FormSuccessProps {
  message?: string;

  className?: string;
  icon?: "success" | "info";
}

export const FormSuccess = ({
  message,
  className = "bg-emerald-500/15 text-emerald-500",
  icon = "success",
}: FormSuccessProps) => {
  if (!message) return null;

  const iconNode =
    icon === "info" ? (
      <InfoCircledIcon className="h-4 w-4" />
    ) : (
      <CheckCircledIcon className="h-4 w-4" />
    );

  return (
    <div
      className={cn(
        "p-3 rounded-md flex items-center gap-x-2 text-sm",
        className
      )}
    >
      {iconNode}
      <p>{message}</p>
    </div>
  );
};

export const FormInfo = ({ message }: FormSuccessProps) => {
  return (
    <FormSuccess
      message={message}
      className="bg-blue-500/15 text-blue-500"
      icon="info"
    />
  );
};
