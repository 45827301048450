import { SectionPassageType, SubActivityType } from "@copmer/calculator-widget";

export const LabelMap: Record<SubActivityType | SectionPassageType, string> = {
  [SubActivityType.Port]: "Port",
  [SubActivityType.VesselHire]: "Vessel Hire",
  [SubActivityType.Sailing]: "Sailing",
  [SubActivityType.Ballast]: "Ballast",
  [SubActivityType.BallastBonus]: "Ballast Bonus",
  [SubActivityType.Cleaning]: "Cleaning",
  [SubActivityType.EU_ETS]: "EU ETS",
  [SubActivityType.TotalCost]: "Total Cost",
  [SubActivityType.DespatchCompensation]: "Despatch Compensation",
  [SectionPassageType.Canal]: "Canal",
  [SectionPassageType.PiracyArea]: "Piracy",
  [SectionPassageType.HM_Area]: "War Risk",
  [SectionPassageType.IceArea]: "Ice",
  [SectionPassageType.RoutePoint]: "Route Point",
};

export const getSubActivityLabel = (subActivity: string) => {
  return LabelMap[subActivity as keyof typeof LabelMap] ?? subActivity;
};
